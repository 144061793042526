@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono&family=Noto+Sans+SC&family=Work+Sans&display=swap');

// Our variables
$base-font-family: 'Work Sans', 'Noto Sans SC', sans-serif;
$monospace-font-family: 'Azeret Mono', 'Monaco', monospace;
$base-font-size: 16px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit: 30px;

$text-color: #111;
$background-color: #fdfdfd;
$brand-color: #ad2323;

$grey-color: #757575;
$grey-color-light: lighten($grey-color, 45%);
$grey-color-dark: darken($grey-color, 20%);

// Width of the content area
$content-width: 800px;

$on-palm: 600px;
$on-laptop: 800px;

// Poor man's Dark mode
@media (prefers-color-scheme: dark) {
  html {
    background-color: invert($background-color);
    filter: invert(92%) hue-rotate(180deg);
  }

  iframe,
  img,
  video {
    filter: invert(100%) hue-rotate(180deg);
  }
}

// Make the body take up the full height of the viewport,
// for better dark mode on short pages.
body {
  min-height: 100vh;
}

// whiteglass also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@import "whiteglass";

// Overrides

blockquote {
  font-size: $base-font-size;
  letter-spacing: -0.5px;
}

::selection {
  background-color: lighten($brand-color, 50%);
}
